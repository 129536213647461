import Vue from 'vue';
import Vuex from 'vuex';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    signal: null,
    connectionID: '',
    myself: null,
    opponent: null
  },
  getters: {
  },
  mutations: {
    async startSignal (state) {
      if (!state.signal) {
        var url = 'https://api.reeqzan.com/tictactoeupdate';

        state.signal = new HubConnectionBuilder()
          .withUrl(url)
          .configureLogging(LogLevel.None)
          .build();

        await state.signal.start();
        state.connectionID = state.signal['connection']['connectionId'];
        console.log(`<><><><><>`)
        console.log(`My connection id is: ${state.connectionID}`);
        console.log(`<><><><><>`)
      }
    }
  }
})