<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    <button @click="buttonClicked">
      Test me
    </button>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  methods: {
    buttonClicked: async function() {
      var details = await this.$axios.get('/TicTacToe/GetUsers?name=azizi');
      this.$axios.post('/Sender/SendUpdate', { method: 'TestUpdate', data: 'myname' });
      console.log(details);
    }
  },
  async mounted() {
    this.$store.commit('startSignal');

    this.$store.state.signal.on('TestUpdate', (e) => {
      console.log(`${e} from signalr`);
    });

    var abc = await this.$axios.get('/TicTacToe/GetDetails?count=5');
    console.log(abc.data);
  },
  destroyed() {
    this.$store.state.signal.off('TestUpdate');
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
